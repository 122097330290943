<template>
  <div id="app">

    <pre v-if="debug">
    {{ options }}
    {{ questions }}
    </pre>

    <div class="mb-3 row mt-2">
      <div class="col d-flex align-items-center justify-content-between">
        <h1>Quiz/Umfrage Daten</h1>
        <select v-model="options.type_of_request" class="fs-5 form-select w-auto">
          <option value="quiz">Quiz</option>
          <option value="survey">Umfrage</option>
        </select>
        <button @click.prevent="generate_new_question"
                class="btn btn-primary"><i class="fa fa-plus"></i></button>
      </div>
    </div>

    <!-- Here starts a Question. There are more than one possible -->
    <div class="border p-4 mb-2" v-for="(q,idx) in questions" :key="idx">

      <div class="mb-3 row">
        <div class="col d-flex justify-content-between">
          <h2>Frage</h2>
          <button @click.prevent="remove_question(idx)" class="btn btn-sm btn-danger">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-form-label col-sm-2">Titel</label>
        <div class="col-sm-10">
          <resize-textarea v-model="q.title" rows.number="1" min-height.number="36" class="form-control"></resize-textarea>
        </div>
      </div>
      <div class="mb-3 row" v-if="mask_name(q.type).mask != 'fill-gap'">
        <label class="col-form-label col-sm-2">Beschreibung</label>
        <div class="col-sm-10">
          <resize-textarea v-model="q.description" rows.number="1" min-height.number="36" class="form-control"></resize-textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2">Type/Punkte</label>
        <div class="col-sm-5">
          <select v-model="q.type" class="form-select">
            <option v-for="toq in type_of_questions"
                    :key="toq.value"
                    :value="toq.value">{{toq.name}}</option>
          </select>
        </div>

        <div class="col-sm-5">
          <input v-model="q.points" class="form-control" placeholder="Punkte" />
        </div>
      </div>

      <fill-gap v-if="mask_name(q.type).mask == 'fill-gap'"
                :q="q"></fill-gap>

      <check-and-radio v-if="mask_name(q.type).mask == 'list'"
                       :q="q"
                       :submask="mask_name(q.type).submask"></check-and-radio>

    </div>

    <div v-if="questions && questions.length > 0" class="mt-3 d-flex justify-content-end">
      <button @click.prevent="generate_new_question"
              class="btn btn-primary"><i class="fa fa-plus"></i></button>

    </div>

  </div>
</template>

<script>
  import FillGap from './components/fill-gap.vue'
  import CheckAndRadio from './components/check-and-radio.vue'

  export default {
    props: [ 'content', 'focus', 'id' ],
    components: {
      FillGap,
      CheckAndRadio
    },
    data: function () {
      return {
        debug: false,
        options: {
          type_of_request: 'quiz'
        },
        type_of_questions: [
          { name: '---', value: '', mask: '' },
          { name: 'Radio', value: 'radio', mask: 'list' },
          { name: 'Checkbox', value: 'checkbox', mask: 'list' },
          { name: 'Sortierung', value: 'sort', mask: 'list', submask: 'sort' },
          { name: 'Eingabefeld', value: 'input', mask: '' },
          { name: 'Lückentext frei', value: 'fillgap-input', mask: 'fill-gap', submask: '' },
          { name: 'Lückentext Listen', value: 'fillgap-list', mask: 'fill-gap', submask: 'list' },
          { name: 'Lückentext Auswahl', value: 'fillgap-drag', mask: 'fill-gap', submask: 'select' },
        ],
        questions: [],
        question: {
          title: null,
          description: null,
          text: null,
          points: 2,
          type: 0,
          lists: [],
          answers: [
            { n: 0, q: '', a: false }
          ]
        }
      }
    },
    methods: {
      mask_name(question_type) {
        return this.type_of_questions.find((e) => e.value == question_type)
      },
      generate_new_question() {
        this.questions.push( JSON.parse( JSON.stringify( this.question )))
      },
      remove_question(idx) {
        this.questions.splice(idx, 1)
      },
    },
    mounted() {
      // console.log('-quiz-backend:mounted------------------------------------------------')
      // console.log(this.content)
      // console.log('-quiz-backend:mounted------------------------------------------------')

      let x = JSON.parse(this.content)
      // console.log('--------------', x, x['question'], x['questions'])

      if (x && x['questions']) {
        // console.log('questions found')
        x['questions'].forEach( (e) => {
          // console.log('copying object', e )
          this.questions.push(e)
        })
        // console.log( this.questions )
      }
      if(x && x['question']) {
        // console.log('question found')
        this.questions.push(x['question'])
      }
      if (x && x['options']) {
        this.options = x['options']
      }
      // if (x && x['question']) { this.questions.push(x['question']) }
      // if (!x) { this.questions.push( Object.apply({}, this.question)) }
      // if(this.questions.length <= 0) { this.questions.push( Object.apply({}, this.question)) }
      // console.log(this.questions)
      // console.log('-------------------------------------------------')
    },
    watch: {
      'questions': {
        handler (n, o) {
          // console.log(`#text_${this.id}`)

          // clean fill the gap, empty lists left over from editing
          // const questions =
          // this.questions.filter((q) => q.type.startsWith('fillgap'))
          //          .map((question) => {
          //            let new_list = []
          //            const tags = question.description
          //                                 .match(/\{(.+?)\}/g)
          //                                 .map((e) => e.replace(/[{}]/g, ''))
//
          //            question.lists.forEach((list) => {
          //              console.log('clean lists')
          //              let found = tags.find((tag) => tag.name == list)
          //              if (found) new_list.push(list)
          //            })
          //            question.lists = new_list
          //          })

          document.querySelector(`#text_${this.id}`).innerHTML =
            JSON.stringify({
              options: this.options,
              questions: this.questions
            })
        },
        deep: true
      }
    },
  }
</script>

<style lang="scss" scoped>
.text-left {
  text-align: left !important;
}
h1 { font-size: 1.5rem; }
h2 { font-size: 1.2rem; }
h3 { font-size: 1rem; }
mt-1 {
  margin-top: 10px;
}
p,
.text {
  font-size: 1.5rem;
  // text-align: center;
}
</style>
