import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="backend--issue--toggle"
export default class extends Controller {
  static targets = [ 'contentBlock', 'icon' ]

  connect() {
    // console.log('connect backend:issues')
  }
  add_block(evt) {
    evt.preventDefault();

    console.log(evt.target)
    console.log(this)
  }
}
