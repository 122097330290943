<template>
  <div class="mb-3 row">
    <div class="col-sm-8">Lückefülltext <button @click.prevent="debug = !debug">+</button></div>
    <div class="col-sm-4">Listen / Select</div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-8">
      <pre v-if="debug">
        {{q}}
      </pre>
      <resize-textarea v-model="q.description"
                       ref="description"
                       :id="`text-${q.id}`"
                       rows.number="7"
                       class="form-control"
                       @change="parse_description(q)"
                       @resize="resize_description"></resize-textarea>
    </div>
    <div class="col-sm-4 overflow-auto" ref="tag_list">

      <div class="mb-4">
        <div class="d-flex justify-content-between align-middle mb-2">
          <strong class="align-middle">Rules</strong>
          <button @click.prevent="new_rule" class="btn btn-sm bg-secondary text-light">+</button>
        </div>
        <div v-for="rule in this.q.rules"
             :key="rule"
             class="border mb-2">
          <div class="bg-secondary text-white px-2 py-1 d-flex">
            <input v-model="rule.source_field" type="text" class="form-control">
            <select v-model="rule.modus" class="form-select w-25">
              <option value="uniq">Uniq</option>
              <option value="equal">Equal</option>
            </select>
          </div>
          <div v-for="(f,idx) in rule.target_fields" :key="f" class="p-2">
            <select v-model="rule.target_fields[idx]" class="form-select">
              <option value="">--</option>
              <option v-for="t in tags" :key="t" :value="t">{{ t }}</option>
            </select>
          </div>
          <div class="d-flex justify-content-end p-1">
            <button @click.prevent="new_rule_target_field(rule)" class="btn btn-sm text-light bg-secondary">+</button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-middle mb-2">
        <strong class="align-middle">Fields</strong>
      </div>

      <div v-for="l in q.lists" :key="l.id"
            class="border mb-2">
        <div class="bg-secondary text-white px-2 py-1">Werte für "{{l.name}}"</div>
        <dl class="mt-2 px-2 py-1">
          <dd class="mb-3">
            <select v-model="l.type" class="form-select">
              <option value="list">Drop down</option>
              <option value="dragdrop">Drag & Drop</option>
              <option value="text">Text</option>
            </select>
          </dd>
          <dd v-for="(item,idx) in l.answers" :key="idx">
            <div class="d-flex gap-1">
              <input v-model="item.q" type="text" class="col-10">
              <input v-model="item.a" type="checkbox" class="col-1">
            </div>
          </dd>
          <dd>
            <button @click.prevent="add_item_to_list(l)"
                    class="btn btn-sm btn-secondary"><i class="fa fa-plus"></i></button>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'q' ],
  data() {
    return {
      debug: false,
      tags: []
    }
  },
  methods: {
    find_lists(text) {
      if (!text) return []

      const tags = text.match(/\{(.+?)\}/g).map((e) => e.replace(/[{}]/g, ''))
      return tags
    },
    add_item_to_list(list) {
      list.answers.push({ q: '', a: false })
    },
    parse_description() {
      this.tags = this.find_lists(this.q.description)
      this.tags.forEach(t => {
        let list = this.q.lists.find((e) => e.name == t)
        if (list) {
          list.type = list.type ? list.type : 'list'
        } else {
          this.q.lists.push({ name: t, type: 'list', answers: []})
        }
      })
    },
    new_rule() {
      if (!this.q.rules) this.q.rules = []
      this.q.rules.push({
        source_field: '',
        modus: 'uniq',
        target_fields: ['']
      })
    },
    new_rule_target_field(rule) {
      rule.target_fields.push('')
    },
    resize_description() {
      console.log('resize_description')
    },
  },
  mounted() {
    const that = this
    const resize_ob = new ResizeObserver(function(entries) {
	    // since we are observing only a single element, so we access the first element in entries array
	    let rect = entries[0].contentRect
	    // let width = rect.width
	    let height = rect.height
      that.$refs.tag_list.style.height = `${height}px`
	    // console.log('Current Width : ' + width)
	    // console.log('Current Height : ' + height)
    })
    resize_ob.observe( this.$refs.description.$el )
    this.parse_description()
    if (!this.q.rules) {
      this.q.rules = []
    }
  }
}
</script>