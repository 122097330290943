import { Controller } from "@hotwired/stimulus"
import TurbolinksAdapter from "vue-turbolinks"
import ResizeTextarea from 'resize-textarea-vue3'
import { createApp, h } from "vue"

import App from "../../quiz-backend/quiz.vue"

// Connects to data-controller="frontend-quiz-loader"
export default class extends Controller {
  connect() {
    // console.log('************** backend-quiz-loader', this.element.id, this.element, this.element.dataset)
    const params = this.element.dataset
    createApp(App, {...params})
      .use(ResizeTextarea)
      .use(TurbolinksAdapter)
      .mount(`#${this.element.id}`)
  }
}
