// https://hybrd.co/posts/github-issue-style-file-uploader-using-stimulus-and-active-storage

import { Controller } from "@hotwired/stimulus"
// import { FetchRequest } from "@rails/request.js"
import Axios from "axios"

// Connects to data-controller="uploader"

export default class extends Controller {
  authenticity_token = null
  axios = null

  static values = {
    url: String,
    issueId: String,
    lessonId: String,
    exerciseContentId: String,
    source: String,
    sourceId: String,
    sourceIdName: String
  }
  static targets = [ 'attachments' ]

  connect () {
    // console.log('connect uploader', this.element)
    this.authenticity_token = document.head.querySelector('meta[name="csrf-token"]').content

    const AxiosInstance = Axios.create({
      withCredentials: true,
      headers: {
        Credentials: 'same-origin',
        'X-CSRF-Token': this.authenticity_token,
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
        'Expires': '-1'
      },
    })

    AxiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      function (error) {
        // console.log('Axios interceptor ....')
        if (error.response.status === 401) {
          // console.log('unauthorized, logging out ...')
          window.document.location.href = "/"
        }
        return Promise.reject(error.response)
      }
    )
    this.axios = AxiosInstance
  }

  dragOver (evt) {
    evt.preventDefault()
    // console.log('dragOver')
    const drop_element = this.findDropElement(evt.target, "uploader")
    if (drop_element) {
      drop_element.classList.add("in")
    } else {
      this.element.classList.remove("in")
    }
  }

  dragLeave (evt) {
    evt.preventDefault()
    // console.log("dragLeave")
    const drop_element = this.findDropElement(evt.target, "uploader")
    // console.log('dragLeave', drop_element.id, evt.target.element)
    this.element.classList.remove('in')
  }

  drop (evt) {
    evt.preventDefault()
    // console.log(
    //   "drop",
    //   this.urlValue,
    //   this.lessonIdValue,
    //   this.lessonIdValue,
    //   this.issueIdValue,
    //   this.exerciseContentIdValue,
    //   evt.dataTransfer.files
    // )
    this.uploadFiles(evt.dataTransfer.files)
    this.element.classList.remove("in")
  }

  findDropElement (target, check_string) {
    if (target.dataset?.controller?.indexOf(check_string) >= 0) return target

    return this.findDropElement(target.parentElement, check_string)
  }

  pasteUpload (evt) {
    if (!evt.clipboardData.files.length) return

    evt.preventDefault()
    this.uploadFiles(evt.clipboardData.files)
  }

  uploadFiles (files) {
    Array.from(files).forEach(file => this.uploadFile(file))
  }

  uuidv4 () {
    return ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[ 0 ] & 15 >> c / 4).toString(16)
    )
  }

  createUploadWindow (uuid, file_name) {
    const d = document.createElement('div')
    d.id = `_${ uuid }`
    d.classList.add('d-flex', 'flex-column', 'border')
    d.style = 'min-width: 250px; position: fixed; top: 50vh; left: 40vw; padding: 10px; border: 2px solid gray; background-color: white;'
    d.innerHTML =
      `
        <div class="d-flex justify-content-between gap-3" style="width: 100%;">
          <span>${ file_name }</span>
          <span><i class="fa fa-times"></i></span>
        </div>
        <div style="height: 20px;">
          <span class="percentage" style="padding: 2px; color: white; font-size: 12px; text-align: center; display: block; background-color: green; height: 100%; width: 1%;">
            0%
          </span>
        </div>
      `
    document.body.appendChild(d)
  }

  async onUploadProgress (evt, id) {
    const percentage = Math.round((100.0 * evt.received) / evt.size)
    console.log('upload percentage:', evt, percentage)
    document.querySelector(`#_${ id } .percentage`).style.width = `${ percentage }%`
    document.querySelector(`#_${ id } .percentage`).innerHTML = `${ percentage }%`
  }

  async uploadFile (file) {
    const that = this
    let uuid = this.uuidv4()

    const form = new FormData()
    form.append('authenticity_token', this.authenticity_token)
    form.append('attachment[document]', file)

    if (this.sourceIdNameValue && this.sourceIdValue) {
      form.append(this.sourceIdNameValue, this.sourceIdValue)
    } else {
      console.log('missing source_id_name', this.sourceIdNameValue)
      console.log('missing source_id', this.sourceIdValue)
    }

    if (this.issueIdValue) form.append('issue_id', this.issueIdValue)
    if (this.lessonIdValue) form.append('lesson_id', this.lessonIdValue)
    if (this.exerciseContentIdValue) form.append('exercise_content_id', this.exerciseContentIdValue)

    this.attachmentsTarget.style.display = 'block'
    this.createUploadWindow(uuid, file.name)

    const progress_id = setInterval(() => {
      this.axios
        .get(`/progress?X-Progress-ID=${ uuid }`)
        .then((response) => {
          console.log("got response", response.data)
          this.onUploadProgress(
            JSON.parse(response.data.replaceAll(/[;)(]/gi, "")),
            uuid
          )
        })
        .catch((error) => {
          console.log("error response", error)
        })
    }, 1000)

    await this.axios
      .post(`${ this.urlValue }?X-Progress-ID=${ uuid }`, form, {
        headers: {
          Accept:
            "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
        }
      })
      .then((response) => {
        // console.log( 'got response', response.data )
        document.querySelector(`#_${ uuid }`).remove()
        clearInterval(progress_id)
        window.Turbo.renderStreamMessage(response.data)
      })
      .catch((error) => {
        console.log("error response", error)
        document.querySelector(`#_${ uuid }`).remove()
        clearInterval(progress_id)
      })
  }

  withProgress (request) {
    console.log('withProgress', request)

    return request.then((response) => {
      console.log('withProgress response', response)
    })
  }

  isImage (contentType) {
    return [
      "image/webp",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
    ].includes(contentType)
  }
}
