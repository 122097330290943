import { Controller } from "@hotwired/stimulus"
import autosize from "autosize"

// Connects to data-controller="backend--issue--toggle"
export default class extends Controller {
  static targets = [ 'contentBlock', 'icon',
                     'destroy', 'contentContentBlock',
                     'contentCheckCriteria' ]

  connect() {
    // console.log('connect backend:issue')
  }
  delete(evt) {
    // console.log('delete')
    evt.preventDefault();
    this.element.setAttribute("hidden", "hidden")
    this.destroyTarget.value = true
  }
  toogle(evt) {
    evt.preventDefault();

    // console.log(evt.target)
    // console.log(this)

    if (this.contentBlockTarget.getAttribute('hidden')) {
      this.contentBlockTarget.removeAttribute('hidden')
      this.iconTarget.classList.remove('fa-caret-right')
      this.iconTarget.classList.add('fa-caret-down')
    } else {
      this.contentBlockTarget.setAttribute('hidden', 'hidden')
      this.iconTarget.classList.add('fa-caret-right')
      this.iconTarget.classList.remove('fa-caret-down')
    }
    autosize.update(document.querySelectorAll("textarea"))
  }
  toggleCheckCriteria(evt) {
    evt.preventDefault()
    if (this.contentCheckCriteriaTarget.getAttribute('hidden')) {
      this.contentCheckCriteriaTarget.removeAttribute("hidden")
      // this.contentContentBlockTarget.setAttribute("hidden", "hidden")
    } else {
      this.contentCheckCriteriaTarget.setAttribute("hidden", 'hidden')
      // this.contentContentBlockTarget.removeAttribute("hidden")
    }
  }
}
