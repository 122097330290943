<template>
  <div class="mb-3 row">
    <label class="col-form-label col-sm-2"></label>
    <label class="col-form-label col-sm-8 text-left">
      <button @click.prevent="add_answer(q)" class="btn btn-sm btn-primary">
        <i class="fa fa-plus"></i>
      </button>
      Antwort
    </label>
    <label class="col-form-label col-sm-1 text-left">OK</label>
    <label class="col-form-label col-sm-1 text-left">...</label>
  </div>

  <div v-for="( answer, i ) in q.answers" :key="i" class="mb-3 row">
    <template v-if="q.type != 'sort'" class="d-flex">
      <div class="col-sm-8 offset-sm-2">
        <resize-textarea v-model="answer.q"
                         :rows="1"
                         :min-height="36" class="form-control"></resize-textarea>
      </div>
      <div class="col-sm-1">
        <input v-model="answer.a" @click="check_values(q,i)" type="checkbox" class="form-check-input" />
      </div>
      <div class="col-sm-1">
        <button @click.prevent="remove_answer(answer,i)" class="btn btn-sm btn-danger">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </template>

    <template v-if="submask == 'sort'" class="d-flex">
      <div class="col-sm-1 offset-sm-2">
        <input v-model="answer.n" type="text" class="form-control" />
      </div>
      <div class="col-sm-7">
        <resize-textarea v-model="answer.q" :rows="1" :min-height="36" class="form-control"></resize-textarea>
      </div>
      <div class="col-sm-1">
        <input v-model="answer.a" @click="check_values(q,i)" type="checkbox" class="form-check-input" />
      </div>
      <div class="col-sm-1">
        <button @click.prevent="remove_answer(answer,i)" class="btn btn-sm btn-danger">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: [ 'q', 'submask' ],
  methods: {
    check_values (question,index) {
      if( question.type === 'radio' ) {
        question.answers.forEach( ( e, idx ) => {
          if( index !== idx ) e.a = false
        })
      }
    },
    add_answer (question) {
      // console.log(question)
      if( question && !question.answers ) { question.answers = [] }
      question.answers.push( { q: '', a: false } )
    },
    remove_answer (answer, idx) {
      // this.$delete(question.answers, idx)
      this.q.answers.splice( this.q.answers.indexOf(answer), 1)
    }
  }
}
</script>