import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

// Connects to data-controller="backend--sort"
export default class extends Controller {
  connect() {
    // console.log(
    //  'connect backend:sort',
    //  this,
    //  this.element,
    //  document.getElementsByClassName('tab-panel')
    // )

    // TODO: id as parameter
    // Current problem is, that id has been sent from turbo-frame

    const issueBlocks = document.getElementById('issue-blocks')

    this.sortable = Sortable.create(issueBlocks, {
      chosenClass: "tab-panel",
      handle: '.sort-handle',
      onEnd: this.end.bind(this),
    })
  }

  end(event) {
    // console.log('sort, end', event, event.item, event.item.dataset)
    let data = new FormData()
    data.append('id', event.item.dataset.id)
    data.append('position', event.newIndex + 1)
    Rails.ajax({
      url: event.item.dataset.resortUrl,
      type: "PATCH",
      data: data,
    })
  }
}
